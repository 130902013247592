import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class TableComponent implements OnInit {
  listOfData: ArenaDeck[] = [];
  loading = true;

  constructor(private http: HttpClient) {}

  ngOnInit() {
    this.http
      .get<ArenaDeck[]>('https://sapi.moecube.com:444/ygopro/analytics/deck/type', {
        params: {
          type: 'day',
          source: 'mycard-athletic'
        }
      })
      .subscribe((data: ArenaDeck[]) => {
        this.listOfData = data;
        this.loading = false;
      });
  }
}

interface ArenaDeck {
  count: string;
  name: string;
  recent_time: string;
  source: string;
  tags: string[];
}
