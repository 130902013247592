<table class="table table-white table-hover">
  <thead>
  <tr>
    <th>Rank</th>
    <th>Deck</th>
    <th>Count</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let data of listOfData | slice:0:10; let i = index">
    <td>{{ i+1 }}</td>
    <td>{{ data.name }}</td>
    <td>{{ data.count }}</td>
  </tr>
  </tbody>
</table>
