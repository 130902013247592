import {HttpClient} from "@angular/common/http";
import {map} from "rxjs/operators";
import yaml from 'yaml';
import {firstValueFrom} from "rxjs";
import {Component, Inject, LOCALE_ID, OnInit, ViewChild, ElementRef, AfterViewInit} from '@angular/core';
import { HostListener } from '@angular/core';


interface HomePageMatchCountDto {
  count: number;
  year: number;
  month: number;
}

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit, AfterViewInit {
  matchCount: HomePageMatchCountDto = {
    count: 100,
    year: 1000,
    month: 1
  };

  latest_win32 = this.http
    .get('https://cdn02.moecube.com:444/downloads/latest.yml', {responseType: 'text'})
    .pipe(map(rawData => 'https://cdn02.moecube.com:444/downloads/' + yaml.parse(rawData).path));

  latest_linux = this.http
    .get('https://cdn02.moecube.com:444/downloads/latest-linux.yml', {responseType: 'text'})
    .pipe(map(rawData => 'https://cdn02.moecube.com:444/downloads/' + yaml.parse(rawData).path));

  latest_drawin = this.http
    .get('https://cdn02.moecube.com:444/downloads/latest-mac.yml', {responseType: 'text'})
    .pipe(map((rawData) => 'https://cdn02.moecube.com:444/downloads/' + yaml.parse(rawData).path.replace('-mac.zip', '.dmg')));

  latest_current = ''

  stats_signups = this.http
    .get('https://ygobbs.com/admin/dashboard.json', {
      params: {api_key: 'dc7298a754828b3d26b709f035a0eeceb43e73cbd8c4fa8dec18951f8a95d2bc', api_username: 'zh99998'}
    })
    .pipe(map((data: any) => data.global_reports.find((item: { type: any; }) => item.type === 'signups').total));

  stats_online = this.http.get('https://api.moecube.com/stats/online', {responseType: 'text'}).pipe(
    map(rawText => {
      const doc = new DOMParser().parseFromString(rawText, 'text/xml');
      const node = doc.querySelector('#content > table > tbody > tr:nth-child(2) > td:nth-child(2)');
      return parseInt(node?.textContent ?? '0');
    })
  );

  constructor(private http: HttpClient, @Inject(LOCALE_ID) public locale: string) {
  }


  @ViewChild('carousel', { static: true }) carousel!: ElementRef;

  ngAfterViewInit(): void {
    const carouselElement = this.carousel.nativeElement;
    const carousel = new (window as any)['bootstrap'].Carousel(carouselElement, {
      interval: 2000, // 设置轮播间隔时间为2秒
      pause: 'hover', // 鼠标悬停时是否暂停轮播
      ride: true, // 自动开始轮播
    });
  }


// 下载
  async ngOnInit() {
    if (navigator.platform.match(/Android/i)) {
      this.latest_current = 'https://ygom.top/';
    } else if (navigator.platform.match(/Mac/i)) {
      this.latest_current = await firstValueFrom(this.latest_drawin);
    } else if (navigator.platform.match(/Linux/i)) {
      if (window.innerHeight > window.innerWidth) {
        this.latest_current = 'https://ygom.top/';
      } else {
        this.latest_current = await firstValueFrom(this.latest_linux);
      }

    } else {
      this.latest_current = await this.latest_win32.toPromise() as string;
    }
    this.matchCount = await this.http.get<HomePageMatchCountDto>('https://sapi.moecube.com:444/ygopro/arena/homepageCount', {
      responseType: 'json'
    }).toPromise() as HomePageMatchCountDto;
  }


  setLocale(locale: any) {
    document.cookie = `locale=${locale}`;
    location.reload();
  }

  navigateToUrl(url: string) {
    location.href = url;
  }


}
